.title {
    font-size: 50px
}

.progress {
    margin: 15px;
}

.progress .progress-bar.active {
    font-weight: 700;
    animation: progress-bar-stripes .5s linear infinite;
}

.loader {
    width: 40%
}

.container-loader {
    text-align: center
}